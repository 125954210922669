import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Accordion from '../../../components/Accordion.js'
import Layout from '../../../components/layout'

const TeluguAll = ({data}) => {
    return <Layout>
        <Helmet>
            <title>Telugu Purohit in Bangalore (15+yrs Exp): Book Telugu Pujari in Bangalore, Telugu Purohits in Bangalore, Telugu Brahmin Purohits in Bangalore, Andhra Purohits in Bangalore & Telugu Pandit in Bangalore</title>
            <meta name="description" content="Looking for Telugu Purohit in Bangalore? We provide the best Telugu Pujari in Bangalore, Telugu Purohits in Bangalore, Andhra Purohits in Bangalore, Telugu Pandit in Bangalore & Telugu Brahmin Purohits in Bangalore for Marriage, Grihapravesham and other pujas in Bengaluru, Karnataka." />
            <script type="application/ld+json">{`
                 {
                  "@context":"https://schema.org",
                  "@type":"FAQPage",
                  "mainEntity": [
                    {
                      "@type":"Question",
                      "name":"Should the customer arrange for Puja Samagri or would the Panditji arrange?",
                      "acceptedAnswer": 
                      {
                        "@type":"Answer",
                        "text":"Customers have the flexibility to book the Puja along with Samagri or book only Panditji for the Puja"
                      }
                    },
                    {
                      "@type":"Question",
                      "name":"Do multiple Pandits call the customer at once upon enquiry, causing them inconvenience?",
                      "acceptedAnswer":
                      {
                        "@type":"Answer",
                        "text":"No. Namaste God values your time and comfort, our team after understanding your requirements arranges a qualified Panditji for the call. In case the customer not satisfied with the Panditji, our team will connect you with another Panditji."
                      }
                    },
                    {
                      "@type":"Question",
                      "name":"Will the Panditji help the customer in choosing good muhurth for ritual?",
                      "acceptedAnswer":
                      {
                        "@type":"Answer",
                        "text":"Yes, our Panditji upon connecting with the customer, will refer to the Panchang and provide an appropriate date and time for the ritual to be performed."
                      }
                    },
                    {
                      "@type":"Question",
                      "name":"Would the Puja be performed as per the Telugu traditions?",
                      "acceptedAnswer":
                      {  
                        "@type":"Answer",
                        "text":"Namaste God provides a wide variety of qualified and experienced panditjis who are hailing from Andhra Pradesh and Telangana. Our team would connect you with the appropriate Panditji upon your requirement and you can discuss about the vidi and vidhan prior to the Puja being booked."
                      }
                    },
                    {
                      "@type":"Question",
                      "name":"How can the customers pay? Through cash or Wallet or Netbanking?",
                      "acceptedAnswer":
                      {
                        "@type":"Answer",
                        "text":"Customers can pay through Cash or Net Banking or UPI Payments (PayTM, GooglePay) or e-Wallet (PayTM)"
                      }
                    }
                  ]
                }
            `}</script>
            <script type="application/ld+json">{`
                {
                  "@context": "http://schema.org",
                  "@type": "ImageObject",
                  "author": "NamasteGod",
                  "name": "${data.allMarkdownRemark.edges[0].node.frontmatter.title}",
                  "contentUrl": "https://www.namastegod.com${data.allFile.nodes[0].childImageSharp.fluid.src}",
                  "contentLocation": "Bangalore, India"
                }
            `}</script>
            <script type="application/ld+json">{`
                {
                  "@context": "http://schema.org",
                  "@type": "Product",
                  "name": "Telugu Purohit in Bangalore",
                  "image": "https://www.namastegod.com${data.allFile.nodes[0].childImageSharp.fluid.src}",
                  "description": "Looking for Telugu Purohits in Bangalore? We provide the best Telugu Pujari in Bangalore, Telugu Purohit in Bangalore, Andhra Purohits in Bangalore, Telugu Pandit in Bangalore & Telugu Brahmin Purohits in Bangalore for Marriage, Grihapravesham and other pujas in Bengaluru, Karnataka.",
                  "sku": "0000000004",
                  "mpn": "000004",
                  "brand": {
                    "@type": "Brand",
                    "name": "NamasteGod"
                  },
                  "review": {
                    "@type": "Review",
                    "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5",
                      "bestRating": "5"
                    },
                    "author": {
                      "@type": "Person",
                      "name": "Rekha Anoop"
                    }
                  },
                  "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.8",
                    "reviewCount": "101"
                  },
                  "offers": {
                    "@type": "Offer",
                    "url": "https://www.namastegod.com/findonline/hindu/telugu-brahmin-purohits-or-andhra-pandits-in-bangalore-india-vedic-pujari-experienced-priests",
                    "priceCurrency": "INR",
                    "price": "3100",
                    "priceValidUntil": "2020-12-31",
                    "itemCondition": "https://schema.org/UsedCondition",
                    "availability": "https://schema.org/InStock",
                    "seller": {
                      "@type": "Organization",
                      "name": "NamasteGod"
                    }
                  }
                }
            `}</script>
            <script type="application/ld+json">{`
                {
                  "@context": "http://schema.org",
                  "@type": "LocalBusiness",
                  "name": "Telugu Purohit in Bangalore",
                  "telephone": "(+91) 81972 49957",
                  "priceRange": "₹₹₹",
                  "url": "https://www.namastegod.com/findonline/hindu/telugu-brahmin-purohits-or-andhra-pandits-in-bangalore-india-vedic-pujari-experienced-priests",
                  "image": "https://www.namastegod.com${data.allFile.nodes[0].childImageSharp.fluid.src}",
                  "address":
                  {
                    "@type": "PostalAddress",
                    "addressLocality": "Bangalore",
                    "addressRegion": "KA",
                    "addressCountry": "IN",
                    "postalCode": "560078",
                    "streetAddress": "#142/1, 17th Cross, 18th Main, JP Nagar 5th Phase"
                   }
                }
            `}</script>
        </Helmet>
        <div id="main" className="pandit-listing">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1 className="desktop">Telugu Purohits in Bangalore (15+yrs Exp):<br/>Book Telugu Pujari & Andhra<br/>Brahmin Pandits in Bangalore</h1>
                        <h1 className="mobile">Telugu Purohits in Bangalore<br/>(15+yrs Exp): Book Telugu Pujari <br/>& Andhra Brahmin<br/>Pandits in Bangalore</h1>
                    </header>
                    <a href="https://g.co/kgs/MciV3q">
                        <span className="reviews">
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <span>Reviews</span>
                        </span>
                    </a>
                    <p>Looking for Telugu Purohits in Bangalore? We provide the best Telugu Pujari in Bangalore, Telugu <a href='https://en.wikipedia.org/wiki/Purohit' className='no-highlight'>Purohit</a> in Bangalore, Andhra Purohits in Bangalore, Telugu Pandit in Bangalore & Telugu Brahmin Purohits in Bangalore for Marriage, Grihapravesham and other pujas in Bengaluru, Karnataka.</p>
                    <Link className="button special" to="#contact">Contact Us</Link>
                    <Accordion
          id="1"
          question=" more"
          answer="<h2>Top 5 Telugu Purohits in Bangalore</h2>
          <table>
            <tbody>
                <tr>
                    <th>Name</th><th>Region</th>
                    <th>Yrs of Exp</th><th>Pujas Done</th>
                </tr>
                <tr>
                    <td>Pandit Bharat Kumar</td>
                    <td>Andhra Pradesh, Telangana, Karnataka</td><td>12+</td>
                    <td>5000+</td>
                </tr>
                <tr>
                    <td>Pandit Manjunath</td><td>Andhra Pradesh, Telangana, Karnataka</td>
                    <td>12+</td><td>3000+</td>
                </tr>
                <tr>
                    <td>Pandit Srinivas</td>
                    <td>Andhra Pradesh, Telangana</td>
                    <td>10+</td>
                    <td>2000+</td>
                </tr>
                <tr>
                    <td>Pandit Jagadeesh</td>
                    <td>Andhra Pradesh, Telangana</td><td>10+</td>
                    <td>2000+</td>
                </tr>
                <tr>
                    <td>Nanjunda Shastry</td>
                    <td>Andhra Pradesh, Telangana, Karnataka</td>
                    <td>30+</td>
                    <td>5000+</td>
                </tr>
            </tbody>
        </table>
        <h3>Frequently Asked Questions</h3>
<ol>
<li class='bold'>Should the customer arrange for Puja Samagri or would the Panditji arrange?
<span>Customers have the flexibility to book the Puja along with Samagri or book only Panditji for the Puja.</span></li>
<li class='bold'>Do multiple Pandits call the customer at once upon enquiry, causing them inconvenience?
<span>No. Namaste God values your time and comfort, our team after understanding your requirements arranges a qualified Panditji for the call. In case the customer not satisfied with the Panditji, our team will connect you with another Panditji.</span></li>
<li class='bold'>Will the Panditji help the customer in choosing good muhurth for ritual?
<span>Yes, our Panditji upon connecting with the customer, will refer to the Panchang and provide an appropriate date and time for the ritual to be performed.</span></li>
<li class='bold'>Would the Puja be performed as per the Telugu traditions?
<span>Namaste God provides a wide variety of qualified and experienced panditjis who are hailing from Andhra Pradesh and Telangana. Our team would connect you with the appropriate Panditji upon your requirement and you can discuss about the vidi and vidhan prior to the Puja being booked.</span></li>
<li class='bold'>How can the customers pay? Through cash or Wallet or Netbanking?
<span>Customers can pay through Cash or Net Banking or UPI Payments (PayTM, GooglePay) or e-Wallet (PayTM)</span></li>
</ol>"
        />
                </div>
            </section>
            <section id="two" className="spotlights">
                {data.allFile.nodes.map((value, index) => {
                    return <section>
                        <div className="image">
                            <img src={value.childImageSharp.fluid.src} alt={data.allMarkdownRemark.edges[index].node.frontmatter.title} title={data.allMarkdownRemark.edges[index].node.frontmatter.title}/>
                        </div>
                        <div className="content">
                            <div className="inner">
                                <header className="major">
                                    <h3>{data.allMarkdownRemark.edges[index].node.frontmatter.title_telugu}</h3>
                                </header>
                                <div className="details">
                                    <span className="label">Years of Experience: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.experience}</span><br/>
                                    <span className="label">Completed his Degree: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.degree}</span><br/>
                                    <span className="label">Well Versed in Puja Traditions as per Regions: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.regions_telugu}</span><br/>
                                    <span className="label">In Bangalore since: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.bangalore}</span><br/>
                                    <span className="label">Specialized In: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.specialized}</span><br/>
                                    <span className="label">Puja’s Performed: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.pujas}</span>
                                </div>
                            </div>
                        </div>
                    </section>
                })}
            </section>
        </div>

    </Layout>
}

export const query = graphql`
{
  allMarkdownRemark(sort: {fields: frontmatter___order_telugu}) {
    edges {
      node {
        frontmatter {
          order_telugu
          title
          title_telugu
          experience
          degree
          regions_telugu
          bangalore
          specialized
          pujas
        }
      }
    }
  }
  allFile(filter: {name: {in: ["Pandit Te1Bharat Kumar", "Pandit Te2Manjunath", "Pandit Te3Arun Kumar", "Pandit Te4Jagadeesh", "Pandit Te5Nanjunda"]}} , sort: {fields: name}) {
    nodes {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
}`

export default TeluguAll